import Image from "next/image"
import scalisLogo from "~/public/images/SCALIS_LOGO_BLUE.svg"

type ScalisLogoProps = {
  width?: number;
  height?: number;
}
export const ScalisLogo: React.FC<ScalisLogoProps> = ({width = 100, height = 100}) => (
  <Image
    src={scalisLogo}
    alt="Scalis"
    width={width}
    height={height}
  />
)
