"use client";

import React from "react";
import { useGetAvatar } from "~/hooks/useGetAvatar";
import { ProfilePicture } from "../ProfilePicture";
import Link from "next/link";
import { Button } from "../Button";
import { Icon } from "~/scalis-components/core";
import { AvatarType } from "~/queries/fetchAvatar";

interface Props {
  companyName?: string;
  companyId?: number;
  userSignedIn?: boolean;
  className?: string;
}

const BrandedHeader: React.FC<Props> = ({
  companyId,
  companyName,
  userSignedIn,
  className,
}) => {
  const { data: logo } = useGetAvatar(companyId, AvatarType.companyLogo);

  return (
    <header className="sticky left-0 right-0 top-0 z-10 flex items-center justify-between gap-2 border-b border-white-1-95 bg-white px-2 py-3 md:px-10">
      <div className={`flex items-center gap-2 ${className}`}>
        <ProfilePicture
          src={logo}
          fullName={companyName}
          className="h-10 w-10 rounded-md"
          fallbackClassName="rounded-md"
        />
        <span className="line-clamp-1 text-sm font-semibold text-dark-color-text-100 md:text-xl">
          {companyName}
        </span>
      </div>
      {userSignedIn && (
        <Link
          href="/api/auth/logout"
          prefetch={false}
          className="justify-self-end"
        >
          <Button variant="ghost" className="w-max gap-2 text-xs md:text-sm">
            Sign out
            <Icon icon="fa-regular fa-angle-right" />
          </Button>
        </Link>
      )}
    </header>
  );
};

export default BrandedHeader;
